<template>
    <div>
        <b-modal id="ModalDriverProfileStatusesEdit" @shown="getData()" title="Редактирование статуса" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true">
            <b-row>
                <b-col class="mb-1">
                   <b-form-input v-model="bodyType.status_id" class="custom-control-primary" placeholder="Статус ID">
                    Статус ID
                    </b-form-input>  
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-1">
                   <b-form-input v-model="bodyType.name" class="custom-control-primary" placeholder="Название">
                    Наименование
                    </b-form-input>  
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="bodyType.is_active" class="custom-control-primary">
                    {{ bodyType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>    
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editBodyType"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    component: {
        ToastificationContent,
    },
    props: ['statuses','id'],
    data(){
        return {
            bodyType: {
                name: null,
                id: this.id,
                is_active: true,
                status_id: null,
            },
            divisionsSelectDisabled: false,
            submitButtonDisabled: false,
        }
    },
    methods: {
        clearData() {
            this.bodyType = {
                name: null,
                is_active: null,
                status_id: null,
            }
        },
        getData(){
            this.$http.get(`/driver-profile-statuses/${this.id}/edit`)
            .then(res => {
                this.bodyType.status_id = res.data.id
                this.bodyType.is_active = this.bodyType.is_active ? true : false
                this.$bvModal.show('ModalDriverProfileStatusesEdit')
            })
        },
        closeModal() {
            this.$bvModal.hide('ModalDriverProfileStatusesEdit')
        },
        editBodyType() {
            this.submitButtonDisabled = true
            this.$http.patch(`/driver-profile-statuses/${this.id}`, {
                name : this.bodyType.name,
                is_active: this.bodyType.is_active,
                status_id: this.bodyType.status_id,
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Статус изменён!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
    },
}
</script>