<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить статус" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row>
                <b-col class="mb-1">
                   <b-form-input v-model="bodyType.name" class="custom-control-primary">
                    Наименование
                    </b-form-input>  
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="bodyType.is_active" class="custom-control-primary">
                    {{ bodyType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createBodyType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { name } from 'jssip'
export default {
    component: {
        ToastificationContent,
    },
    props: ['statuses'],
    data(){
        return {
            bodyType: {
                name: null,
                is_active: true,
            },
            submitButtonDisabled: false,
            statusesSelectDisabled: false,
        }
    },
    methods: {
        clearData(){
            this.bodyType = {
                name: null,
                is_active: true,
            }
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        createBodyType() {
            this.submitButtonDisabled = true
            this.$http.post('/driver-profile-statuses', this.bodyType) 
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Статус создан!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        
    }
}
</script>