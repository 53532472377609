<template>
    <div>
      <div
        class="d-flex justify-content-center align-items-center" v-if="showPreloader ||$store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
      <div v-else>
        <table-driver-profile-statuses :options="bodyTypes" @editForm="editForm" />
      </div>
      <modal-driver-profile-statuses :statuses="statuses" @refresh="refresh" />
      <modal-driver-profile-statuses-edit :id="id" :statuses="statuses" @refresh="refresh" />
      <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import ModalDriverProfileStatuses from "@/views/component/Modal/ModalDriverProfileStatuses/ModalDriverProfileStatuses.vue";
import ModalDriverProfileStatusesEdit from "@/views/component/Modal/ModalDriverProfileStatuses/ModalDriverProfileStatusesEdit.vue";
import tableDriverProfileStatuses from "@/views/component/Table/tableDriverProfileStatuses.vue";
export default {
    components: {
        ModalDriverProfileStatuses,
        ModalDriverProfileStatusesEdit,
        tableDriverProfileStatuses,
    },
    data() {
      return {
        bodyTypes: [],
        statuses: [],
        showPreloader: false,
        fields: [
          { key: "id", label: "ID" },
          { key: "name", label: "Название" },
          { key: "is_active", label: "Статус" },
          { key: 'created_user.full_name', label: 'Кто создал'},
        ],
        selectedBodyType: null,
        id:null,
      };
    },
    mounted() {
      this.$store.commit("pageData/setdataCount", null);
      this.openFilter();
      this.getstatuses();
    },
    methods: {
      editForm(id) {
        this.$bvModal.show('ModalDriverProfileStatusesEdit')
        this.id = id
      },
      getstatuses() {
        this.$http.get("/driver-profile-statuses", { params: { is_active: true } }).then((res) => {
          this.statuses = res.data;
        });
      },
      openFilter() {
        let allRoutes = this.$store.state.draggableTab.tabs;
        let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        let arrayRoutes = [];
        allRoutes.forEach((element) => {
          arrayRoutes.push(element.path);
        });
        if (arrayRoutes.includes(this.$route.path) == true) {
          this.showPreloader = true;
          this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
            this.bodyTypes = res.data;
            this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
            this.showPreloader = false;
            this.$store.commit("REFRESH_DATA", false);
          });
        } else if (arrayRoutes.includes(this.$route.path) == false) {
          this.$bvModal.show(this.$route.name + "filter");
        }
      },
      refresh() {
        this.showPreloader = true;
        this.$http
          .get("/driver-profile-statuses")
          .then((res) => {
            this.bodyTypes = res.data;
            this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
            this.showPreloader = false;
            this.$store.commit("REFRESH_DATA", false);
          })
          .catch((err) => {});
      },
      sendToParent(tableData) {
        this.bodyTypes = tableData;
        this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
      },
    },
    computed: {
      fetchingNewData() {
        return this.$store.state.refresh.fetchingNewData;
      },
    },
    watch: {
      fetchingNewData(val) {
        if (val) {
          let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
          this.$http.get(`${this.$route.name}`, { params: params }).then((res) => {
            this.bodyTypes = res.data;
            this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
            this.$store.commit("REFRESH_DATA", false);
          });
        }
      },
    },
  };
  </script>
  